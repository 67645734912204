<template>
  <div>
    <!-- <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{
          backgroundImage:
            'url(' + $store.state.fastPlataforma.banner_plataforma + ')',
          backgroundColor: '#000',
        }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{
                  color: $store.state.fastPersonalizacao.titulo_banner_color
                    ? $store.state.fastPersonalizacao.titulo_banner_color
                    : '#fff',
                  textShadow: '1px 1px #000',
                }"
              >
                {{
                  $store.state.fastPersonalizacao.titulo_banner
                    ? $store.state.fastPersonalizacao.titulo_banner
                    : ""
                }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header> -->
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Minhas turmas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content mt-0">
        <div class="container">
          <div class="mt-3 mb-4">
            <a
              class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <!-- fd-app-welcome -->
                  <section
                    class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide"
                  >
                    <h2
                      class="aluno_font_color mb-0"
                    >
                      Minhas
                      <span>Turmas</span>
                    </h2>
                    
                    <p                            
                      class="aluno_font_color my-0"
                    >
                      Veja as turmas em que você está matriculado
                    </p>
                    <div>
                      <img
                        :src="
                          require('@/assets/images/separador.png')
                        "
                      >
                    </div>
                  </section>
                  <!-- /fd-app-welcome -->
                  <div class="row">
                    <div
                      class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1"
                    >
                      <h4
                        class="aluno_font_color"                                 
                      >
                        Lista de turmas ({{ fastAlunoTurmasFiltro.length }})
                      </h4>                                
                    </div>

                    <div class="col-12 fd-app-minhas-turmas">
                      <div v-for="(matricula, index) in fastAlunoTurmasFiltro" :key="index">
                        <div class="col-12 text-center text-orange" v-if="matricula.matricula_por_programa">
                          <span v-if="matricula.qtde_modulos_compra == 1">ACESSO: 1 MÓDULO</span>
                          <span v-else-if="matricula.qtde_modulos_compra > 1">ACESSO: {{ matricula.qtde_modulos_compra }} MÓDULOS</span>
                          <span v-else>ACESSO: NENHUM MÓDULO</span>
                        </div>
                        <div class="col-12 text-center text-orange" v-else-if="matricula.matricula_por_unidade">
                          <span v-if="matricula.qtde_unidades_compra == 1">ACESSO: 1 UNIDADE CURRICULAR</span>
                          <span v-else-if="matricula.qtde_unidades_compra > 1">ACESSO: {{ matricula.qtde_unidades_compra }} UNIDADES CURRICULARES</span>
                          <span v-else>ACESSO: NENHUMA UNIDADE CURRICULAR</span>                                            
                        </div>
                        <div class="col-12 text-center text-orange" v-else>
                          <span>ACESSO: CURSO COMPLETO</span>
                        </div>
                        <div class="card mb-4">
                          <div class="card-header text-center text-wrap">
                            <div class="position-absolute">MATRÍCULA <h3>#{{ matricula.id_matricula }}</h3></div>
                            <span>TURMA</span>
                            <h3>{{ matricula.sigla_turma }}</h3>
                            <span v-if="matricula.turno == 1">TURNO: MANHÃ</span>
                            <span v-else-if="matricula.turno == 2">TURNO: TARDE</span>
                            <span v-else-if="matricula.turno == 3">TURNO: NOITE</span>
                            <span v-else>TURNO: Indefinido</span>
                          </div>
                          <div class="card-body text-center pb-0">
                            <h3 class="text-blue-novo mb-3">{{ matricula.nome_curso }}</h3>
                            <a 
                              :href="'/plataforma/' + parseInt($route.params.id_plataforma) + '/aluno/turma/' + matricula.id_turma + '?matricula=' + base64encode(matricula.id_matricula)" 
                              class="btn-novo btn-primary"
                              @click.prevent="$router.push('/plataforma/' + parseInt($route.params.id_plataforma) + '/aluno/turma/' + matricula.id_turma + '?matricula=' + base64encode(matricula.id_matricula))">ACESSAR</a>
                            <p v-if="matricula.ativo" class="text-orange mt-3">ATIVO</p>
                            <p v-else class="text-orange mt-3">INATIVO</p>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                  <div class="col-12 text-center mt-4">
                    <Pagination
                      :page-size="8"
                      :items="fastAlunoTurmasTotal"
                      @changePage="fastAlunoTurmasFiltro = $event"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../../components/Pagination";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAlunoTurmas",
  components: {
    Pagination,
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Lives
      fastAlunoTurmasTotal: [],
      fastAlunoTurmasFiltro: [],
      fastAlunoTurmasLoading: true
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          console.log("this.$store.state.fastUsuario.Id_pessoa", this.$store.state.fastPermissoes.id_pessoa)
          this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.$store.state.fastPermissoes.id_pessoa)
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastMatriculaAluno(id_plataforma, id_pessoa_aluno) {
      this.fastAlunoTurmasLoading = true
      this.fastAlunoTurmasTotal = [];
      this.fastAlunoTurmasFiltro = [];
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista", "id_plataforma=" + id_plataforma + "&id_programa=0&id_pessoa_aluno=" + id_pessoa_aluno).then(obj => {
        console.log("getFastMatriculasAluno", obj);  
        const matriculas = []       
        if (obj.length) {
          obj.forEach(m => {
            if (m.ativo) {
              matriculas.push(m)
            }
          });
          this.fastAlunoTurmasTotal = matriculas; 
        } 
         this.fastAlunoTurmasLoading = false
      }).catch(e => {
        console.log(e);
        this.fastAlunoTurmasLoading = false
      })
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
